<template>
    <section class="ver-cotizacion-vencidas container-fluid">
        <div class="d-middle cr-pointer icon-option" @click="$emit('goBack')">
            <i class="icon-arrow-left f-18" />
            <p class="f-15">Regresar cotizaciones vencidas</p>
        </div>
        <tabla-general :servidorData="materialesCotizaciones" @paginar="listarPag" :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true">
            <el-table-column label="Referencia" prop="referencia" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
             <el-table-column label="Materiales" prop="materiales">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M" prop="um" width="120" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.configuracion_unidad_medidas.unidad_medida }} ({{ scope.row.configuracion_unidad_medidas.sigla }})
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cantMateriales" width="80" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cantidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor Unitario" prop="valorUnitario" align="center" width="150">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor total" prop="valor" width="150" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor * Number(scope.row.cantidad)) }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            materialesCotizaciones: 'users_providers/usersProviders/materialesCotizaciones',
        }),
    },
    methods: {
        ...mapActions({
            Action_list_materiales_cotizaciones: 'users_providers/usersProviders/Action_list_materiales_cotizaciones',
        }),
        async listarPag(pag){
            console.log(pag);
            await this.Action_list_materiales_cotizaciones({ id: this.materialesCotizaciones.data[0].id_cotizacion, payload: { page: pag }, cotizacion: 'vencidas' });
        }
    },
}
</script>

<style lang="scss" scoped>

</style>